import { useSelector, useDispatch } from 'react-redux';
import { GlobalState } from '../interfaces/redux/GlobalState';
import { StockApi, Article } from '../api/stock';
import { Configuration } from '../api/shared/configuration';

import { createGetArticlesAction, createGetCategoriesAction, createSetArticleAction } from '../redux/action/stockActionFactory';
import { StockArticleDto } from '../api/core-client';

export function useStock() {
    const dispatch = useDispatch();
    const auth = useSelector((state: GlobalState) => state.auth);
    const stockApi = new StockApi(new Configuration ({
        basePath: process.env.REACT_APP_SERVICE_URL_STOCK,
        accessToken: auth?.jwt,
    }));
    const { articles, categories } = useSelector((state: GlobalState) => state.stock);

    async function fetchArticles() {
        try {
            const response = await stockApi.listArticles();
            
            if (response) {
                dispatch(createGetArticlesAction(response.data)); 
            }
        } catch (error) {
        }
    }

    async function deleteCategory(id: string) {
        try {
            await stockApi.deleteCategory(id);
        } catch(error) {
        }
    }

    async function fetchCategories() {
        const response = await stockApi.getCategories();
        
        if (response)
        {
            dispatch(createGetCategoriesAction(response.data));
        }
    }

    function setArticle(article: Article) {
        dispatch(createSetArticleAction(article));
    }

    function getTreeTableCategories() {
        const allCategories = Object.values(categories);
        const obj: any = {};

        allCategories.forEach((category) => {
            if (!category.id) {
                return;
            }

            Object.assign(obj[category.id] = obj[category.id] || {}, {
                key: category.id.toString(),
                data: {
                    id: category.id.toString(),
                    name: category.categoryName,
                    description: category.description,
                    parentId: category.parentId
                }
            });

            if (!category.parentId) {
                return;
            }

            obj[category.parentId] = obj[category.parentId] || {};
            obj[category.parentId].children = obj[category.parentId].children || [];
            obj[category.parentId].children.push(obj[category.id]);
        });

        return Object.keys(obj).map((key) => {
            const category = obj[key];

            if (category.data.parentId) {
                return null;
            }

            return category;
        }).filter((category) => category !== null);
    }

    function getSelectOptions() {
        const allCategories = Object.values(categories);

        return allCategories.map((category) => ({
            value: category.id,
            label: category.categoryName
        }));
    }

    return {
        fetchArticles,
        fetchCategories,
        articles,
        categories,
        getTreeTableCategories,
        getSelectOptions,
        setArticle,
        stockApi,
        deleteCategory
    };
}
