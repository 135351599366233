import {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { TreeSelect, TreeSelectChangeEvent } from 'primereact/treeselect';
import { TreeNode } from 'primereact/treenode';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber, InputNumberChangeEvent } from 'primereact/inputnumber';
import { classNames } from 'primereact/utils';
import { ToggleButton } from 'primereact/togglebutton';
import { Mention, MentionSearchEvent } from 'primereact/mention';
import { Button } from 'primereact/button';
import { useStock } from '../../../../hooks/useCore';
import {
  StockArticleDto,
  StockArticleSummaryDto,
  StockStorageLocationDto,
} from '../../../../api/core-client';

export interface BOMItemValue {
  categoryId?: number;
  articleId?: number;
  assignedLocationId?: number;
  pickedLocationId?: number;
  assignedAmount?: number;
  assignedAmountExpression?: string;
  pickedAmount?: number;
  instructions?: string;
}

export type BOMItemProps = {
  mode?: 'TEMPLATE' | 'EDIT' | 'VIEW';
  value?: BOMItemValue;
  onUpdate?: (newValue: BOMItemValue) => void;
  onGetDataSet?: (datasetType: string) => unknown[];
  _onDestroy?: () => void;
};

export const BOMItem: FC<BOMItemProps> = ({
  mode = 'TEMPLATE',
  value,
  onUpdate,
  onGetDataSet,
  _onDestroy,
}) => {
  const { t } = useTranslation();

  const [activeValue, setActiveValue] = useState<BOMItemValue | undefined>(
    value,
  );

  const stock = useStock();

  // const [categoryTree, setCategoryTree] = useState<TreeNode[]>([]);
  const [articlesTree, setArticlesTree] = useState<TreeNode[]>([]);

  const [currentArticles, setCurrentArticles] = useState<
    (StockArticleSummaryDto | StockArticleDto)[]
  >([]);

  const [currentArticleLocations, setCurrentArticleLocations] = useState<
    (StockStorageLocationDto & { amount?: number })[]
  >([]);

  const [useExpressionInput, setUseExpressionInput] = useState<boolean>(false);
  const [expressionInputSuggestions, setExpressionInputSuggestions] = useState<
    any[]
  >([]);

  const selectedArticleData = useMemo(() => {
    if (activeValue?.articleId !== undefined) {
      let found = currentArticles.find((a) => a.id == activeValue.articleId);
      if (found) return found;
    }
    return undefined;
  }, [activeValue?.articleId]);

  useEffect(() => {
    stock.ListArticlesForCategoryId(activeValue?.categoryId).then((data) => {
      setCurrentArticles(data);
    });
  }, [activeValue?.categoryId]);

  useEffect(() => {
    stock
      .GetAvailableArticleLocations(activeValue?.articleId)
      .then((data) => setCurrentArticleLocations(data));
  }, [activeValue?.articleId]);

  useEffect(() => {
    stock.ListArticlesForCategoryId(activeValue?.categoryId).then((d) => {
      setArticlesTree(
        d
          .sort((a, b) => (a.name! < b.name! ? -1 : a.name == b.name ? 0 : 1))
          .map<TreeNode>((x) => {
            return {
              key: String(x.id),
              data: x,
              id: String(x.id),
              label: x.name,
            };
          }),
      );
    });
  }, [currentArticles]);

  // useEffect(() => {
  //   if (!stock.categories || Object.keys(stock.categories).length == 0) return;

  //   let treemap: Record<number, TreeNode> = {};
  //   for (const c of Object.values(stock.categories)) {
  //     if (!treemap[c.id!])
  //       treemap[c.id!] = {
  //         key: c.id?.toString(),
  //         id: c.id?.toString(),
  //         label: c.name,
  //         data: c,
  //         children: [],
  //       };
  //   }

  //   let cattree: TreeNode[] = [];
  //   for (const c of Object.values(stock.categories)) {
  //     const n = treemap[c.id!];
  //     if (c.parentCategoryId && treemap[c.parentCategoryId]) {
  //       treemap[c.parentCategoryId].children?.push(n);
  //     } else {
  //       cattree.push(n);
  //     }
  //   }

  //   setCategoryTree(cattree);
  // }, [stock.categories]);

  useEffect(() => {
    if (onUpdate && activeValue) {
      onUpdate(activeValue);
    }
  }, [activeValue]);

  useEffect(() => {
    setActiveValue(value);
  }, [value]);

  const processSuggestionSearch = useCallback(
    (e: MentionSearchEvent) => {
      let dataset: any[] = [];

      if (onGetDataSet) {
        dataset = onGetDataSet('formfields');
      }

      const ret = dataset.filter(
        (x) =>
          x.label &&
          String(x.label)
            .toLowerCase()
            .startsWith(e.query.replace(/^\$?\./, '').toLowerCase()),
      );
      setExpressionInputSuggestions(ret);
    },
    [activeValue, onGetDataSet],
  );

  if (mode == 'TEMPLATE') {
    return (
      <div className="w-full formgrid grid">
        <div className="field col-12 md:col-6">
          <label htmlFor="category">{t('common:category')}</label>
          <TreeSelect
            options={stock.categoryTree}
            value={activeValue?.categoryId?.toString()}
            onChange={(e: TreeSelectChangeEvent) => {
              setActiveValue((old) => {
                return { ...old, categoryId: Number(e.value) };
              });
            }}
            className="surface-overlay border-1 border-solid surface-border border-round outline-none focus:border-primary w-full"
          />
        </div>
        <div className="field col-12 md:col-6">
          <label>{t('formbuilder:stockitem.article')}</label>
          <TreeSelect
            options={articlesTree}
            value={activeValue?.articleId?.toString()}
            onChange={(e: TreeSelectChangeEvent) => {
              setActiveValue((old) => {
                return { ...old, articleId: Number(e.value) };
              });
            }}
            className="surface-overlay border-1 border-solid surface-border border-round outline-none focus:border-primary w-full"
          />
        </div>
        <div className="field col-12">
          <label>Default amount</label>
          <div className="w-full flex flex-column">
            <div className="p-inputgroup flex">
              {/* <span className="p-inputgroup-addon p-0">
                <ToggleButton
                  className="bg-transparent border-none text-500"
                  onIcon={
                    <svg
                      style={{width: "18px", height:"18px"}}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_iconCarrier">
                        <path
                          d="M13.5 4.50012C10.5 3.50012 9.5 7.00012 9.5 7.00012L7 16.0001C7 16.0001 5.5 20.0001 2.5 19.0001M5.5 9.00007H14L16 15.0001C16 15.0001 17 17.0001 18.5 15.5001M11 15.5C13.5 16.5 16.5 8.50001 19 9.00001"
                          stroke="#000000"
                          strokeWidth="1"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </g>
                    </svg>
                  }
                  offIcon={<>...</>}
                  onLabel=""
                  offLabel=""
                  checked={useExpressionInput}
                  onChange={(e)=>setUseExpressionInput(e.value)}
                />
              </span> */}
              {!useExpressionInput && (
                <InputNumber
                  className="focus:border-primary flex-1"
                  step={0.01}
                  min={0}
                  value={activeValue?.assignedAmount}
                  onChange={(e: InputNumberChangeEvent) => {
                    setActiveValue((old) => {
                      return { ...old, assignedAmount: e.value || 0 };
                    });
                  }}
                />
              )}
              {useExpressionInput && (
                <Mention
                  rows={1}
                  trigger={'$.'}
                  value={activeValue?.assignedAmountExpression}
                  onChange={(e) =>
                    setActiveValue((old) => {
                      return {
                        ...old,
                        assignedAmountExpression: e.currentTarget.value,
                      };
                    })
                  }
                  suggestions={expressionInputSuggestions}
                  onSearch={processSuggestionSearch}
                  field={'label'}
                />
              )}
              <span
                className={classNames('p-inputgroup-addon', {
                  hide: !selectedArticleData?.unit,
                })}
              >
                {selectedArticleData?.unit}
              </span>
            </div>
          </div>
        </div>
        {_onDestroy && (
          <div className="col-12">
            <div className="u-text-align-center">
              <Button
                icon={<span className={`pr-2 pi pi-trash`} />}
                onClick={_onDestroy}
              >
                {t('actions.delete')}
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  } else if (mode == 'EDIT') {
    return (
      <div className="w-full formgrid grid">
        <div className="field col-12 md:col-6">
          <label htmlFor="category">{t('common:category')}</label>
          <TreeSelect
            options={stock.categoryTree}
            value={activeValue?.categoryId?.toString()}
            onChange={(e: TreeSelectChangeEvent) => {
              setActiveValue((old) => {
                return { ...old, categoryId: Number(e.value) };
              });
            }}
            className="surface-overlay border-1 border-solid surface-border border-round outline-none focus:border-primary w-full"
          />
        </div>
        <div className="field col-12 md:col-6">
          <label>{t('formbuilder:stockitem.article')}</label>
          <TreeSelect
            options={articlesTree}
            value={activeValue?.articleId?.toString()}
            onChange={(e: TreeSelectChangeEvent) => {
              setActiveValue((old) => {
                return { ...old, articleId: Number(e.value) };
              });
            }}
            className="surface-overlay border-1 border-solid surface-border border-round outline-none focus:border-primary w-full"
          />
        </div>
        <div className="field col-12">
          <label>Location</label>
          <Dropdown
            className="surface-overlay border-round outline-none focus:border-primary w-full"
            options={currentArticleLocations}
            optionValue="id"
            optionLabel="name"
            value={activeValue?.assignedLocationId}
            onChange={(e) => {
              setActiveValue((old) => {
                return { ...old, assignedLocationId: Number(e.value) };
              });
            }}
          />
        </div>
        <div className="field col-12">
          <label>Amount</label>
          <div className="p-inputgroup flex">
            <InputNumber
              className="surface-overlay border-round outline-none focus:border-primary w-full"
              step={0.01}
              min={0}
              value={activeValue?.assignedAmount}
              onChange={(e) => {
                setActiveValue((old) => {
                  return { ...old, assignedAmount: Number(e.value) };
                });
              }}
            />
            <span
              className={classNames('p-inputgroup-addon', {
                hide: !selectedArticleData?.unit,
              })}
            >
              {selectedArticleData?.unit}
            </span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full formgrid grid">
      <div className="field col-12 md:col-6">
        <label htmlFor="category">{t('common:category')}</label>
        <div className="flex flex-row">
          <span className="surface-overlay p-2 align-content-center inline-block font-italic outline-none focus:border-primary w-full h-2rem border-1 border-black-alpha-20">
            {activeValue?.categoryId
              ? stock.categories[activeValue.categoryId].name
              : ' '}
          </span>
        </div>
      </div>
      <div className="field col-12 md:col-6">
        <label>{t('formbuilder:stockitem.article')}</label>
        <div className="flex flex-row">
          <span className="surface-overlay p-2 align-content-center inline-block font-italic outline-none focus:border-primary w-full h-2rem  border-1 border-black-alpha-20">
            {activeValue?.articleId
              ? currentArticles[activeValue.articleId].name
              : ' '}
          </span>
        </div>
      </div>
      <div className="field col-12">
        <label>Location</label>
        <div className="flex flex-row gap-3">
          <span className="surface-overlay p-2 align-content-center inline-block font-italic outline-none focus:border-primary w-full  border-1 border-black-alpha-20">
            {activeValue?.assignedLocationId ? currentArticleLocations.find(x=>x.id == activeValue.assignedLocationId)?.name  ?? `id: ${activeValue.assignedLocationId}`: '<location>'}
          </span>
          <Dropdown
            className="surface-overlay border-round outline-none focus:border-primary w-full"
            options={currentArticleLocations}
            optionValue="id"
            optionLabel="name"
            value={activeValue?.pickedLocationId}
            onChange={(e)=>{
              setActiveValue((old) => {
                return { ...old, pickedLocationId: Number(e.value) };
              });
            }}
          />
        </div>
      </div>
      <div className="field col-12">
        <label>Amount</label>
        <div className="flex flex-row gap-3">
          <div className="p-inputgroup flex">
            <span className="surface-overlay p-2 align-content-center inline-block font-italic outline-none focus:border-primary w-full  border-1 border-black-alpha-20">
              {activeValue?.assignedAmount ?? 0.0}
            </span>
            <span className={classNames('p-inputgroup-addon')}>
              {selectedArticleData?.unit}
            </span>
          </div>
          <div className="p-inputgroup flex">
            <InputNumber
              className="surface-overlay border-round outline-none focus:border-primary w-full"
              step={0.01}
              min={0}
              value={
                activeValue?.pickedAmount ?? activeValue?.assignedAmount ?? 0
              }
              onChange={(e)=>{
                setActiveValue((old) => {
                  return { ...old, pickedAmount: Number(e.value) };
                });
              }}
            />
            <span className={classNames('p-inputgroup-addon')}>
              {selectedArticleData?.unit}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
