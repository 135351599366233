import * as yup from 'yup';
import * as yuplocales from 'yup-locales';
import countries from 'i18n-iso-countries';
import Island from '../../components/shared/controls/Island/Island';
import { Button } from 'primereact/button';
import { Column, ColumnEditorOptions } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Form, Formik, FormikProps } from 'formik';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { NotificationContext } from '../../context/NotificationContext';
import { Panel } from 'primereact/panel';
import { RouteComponentProps } from 'react-router-dom';
import { TreeSelect } from 'primereact/treeselect';
import { TreeTable, TreeTableSelectionKeysType } from 'primereact/treetable';
import { useStock } from '../../hooks/useCore';
import { useTranslation } from 'react-i18next';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ArticleUnitClassificationEnum,
  StockArticleDto,
  StockCategoryDto,
  StockStorageLocationDto,
  StockUnitTypeDto,
} from '../../api/core-client';
import {
  showErrorNotification,
  showNotification,
  showWarningNotification,
} from '../../utils/notification';

import { FaTrashAlt, PlusMinus } from '@app/sharedicons';
import { MutateStockDialog } from './partials/MutateStockDialog';

const CategoriesAndArticlesPage: FC<RouteComponentProps> = ({ history }) => {
  const { t, i18n } = useTranslation(['common', 'stock', 'notifications']);
  const stockApi = useStock();

  const notificationContext = useContext(NotificationContext);

  const [articles, setArticles] = useState<StockArticleDto[]>([]);

  const [selectedCategoryId, setSelectedCategoryId] = useState<
    | string
    | TreeTableSelectionKeysType
    | TreeTableSelectionKeysType[]
    | undefined
    | null
  >(undefined);

  const [currentCategory, setCurrentCategory] = useState<
    StockCategoryDto | undefined
  >(undefined);

  const [selectedArticles, setSelectedArticles] = useState<
    StockArticleDto | StockArticleDto[] | undefined | null
  >(undefined);

  const hasChildren = useCallback(
    (elemId) => {
      return (
        stockApi.categories.filter((x) => x.parentCategoryId == elemId).length >
        0
      );
    },
    [stockApi.categories],
  );

  const fetchArticles = useCallback(
    (catId: number) => {
      stockApi.ListArticlesForCategoryId(catId, true).then((data) => {
        setArticles(data);
      });
    },
    [stockApi],
  );

  useEffect(() => {
    const found = stockApi.categories?.find((x) => x.id == selectedCategoryId);
    setCurrentCategory(found);

    setSelectedArticles(undefined);
    if (selectedCategoryId) fetchArticles(Number(selectedCategoryId));
  }, [selectedCategoryId]);

  const catInfoChanged = useMemo(() => {
    const orig = stockApi.categories?.find((x) => x.id == selectedCategoryId);
    if (orig && currentCategory) {
      return !(
        orig.description == currentCategory.description &&
        orig.name == currentCategory.name
      );
    }

    return false;
  }, [currentCategory, stockApi.categories, selectedCategoryId]);

  const createNewCategory = useCallback(() => {
    let parentId: number | undefined = undefined;

    if (selectedCategoryId) {
      parentId = Number(selectedCategoryId);
    }

    let data: StockCategoryDto = {
      description: '',
      name: 'new',
      id: -1 * (stockApi.categories.length ?? 1),
      parentCategoryId: parentId,
    };

    stockApi.setCategories((old) => [...old, data]);
  }, [selectedCategoryId, stockApi.categories, stockApi.setCategories]);

  const saveEditedCategory = useCallback(async () => {
    if (!currentCategory || currentCategory.id === undefined) return;

    let needsUpdate = false;

    if ((currentCategory.id ?? 0) <= 0) {
      // new category
      needsUpdate = await stockApi.AddCategory({
        description: currentCategory.description,
        name: currentCategory.name,
        parentCategoryId: currentCategory.parentCategoryId,
      });
    } else {
      // update category
      needsUpdate = await stockApi.UpdateCategory(currentCategory.id, {
        description: currentCategory.description,
        name: currentCategory.name,
        parentCategoryId: currentCategory.parentCategoryId,
      });
    }

    if (needsUpdate) await stockApi.refreshCategories();
  }, [currentCategory, stockApi]);

  const removeCategory = useCallback(async () => {
    if (currentCategory && (currentCategory.id ?? 0) <= 0) {
      stockApi.setCategories((old) => [
        ...old.filter((x) => x.id != currentCategory.id),
      ]);
      setCurrentCategory(undefined);
    } else if (currentCategory && (currentCategory.id ?? 0) > 0) {
      if (
        stockApi.categories.find(
          (x) => x.parentCategoryId == currentCategory.id,
        )
      ) {
        alert('Een parent category kan niet worden verwijderd.');
        return;
      }

      if (articles && articles.length > 0) {
        alert('Er zijn nog artikelen gekoppeld aan deze categorie.');
        return;
      }

      await stockApi.DeleteCategory(currentCategory.id!);
      await stockApi.refreshCategories();
    }
  }, [currentCategory, stockApi, stockApi.categories, articles]);

  const [showUnitTypesEditDialog, setShowUnitTypesEditDialog] =
    useState<boolean>(false);

  const [showMoveCategoryDialog, setShowMoveCategoryDialog] =
    useState<boolean>(false);

  const [showArticleInformationDialog, setShowArticleInformationDialog] =
    useState<boolean>(false);

  const [showMutateDialog, setShowMutateDialog] = useState<boolean>(false);
  const [mutationDialogArticleLocations, setMutationDialogArticleLocations] =
    useState<StockStorageLocationDto[]>([]);

  const [mutationDialogArticles, setMutationDialogArticles] = useState<
    StockArticleDto[]
  >([]);

  const articleInformationDataRef = useRef<StockArticleDto | undefined>(
    undefined,
  );
  const [articleInformationDialogMode, setArticleInformationDialogMode] =
    useState<'EDIT' | 'VIEW'>('EDIT');

  const [articleUnitTypes, setArticleUnitTypes] = useState<StockUnitTypeDto[]>(
    [],
  );
  const [articleUnitTypes2, setArticleUnitTypes2] = useState<
    StockUnitTypeDto[]
  >([]);

  const articleInfoFormikRef = useRef<FormikProps<StockArticleDto>>(null);

  const OpenEditUnitTypes = useCallback(() => {
    setArticleUnitTypes2(articleUnitTypes);
    setShowUnitTypesEditDialog(true);
  }, [articleUnitTypes]);

  const articleInfoFooter = useMemo(() => {
    return articleInformationDialogMode === 'VIEW' ? undefined : (
      <div className="w-full flex flex-row gap-2">
        <Button
          icon="pi pi-save mr-2"
          onClick={() => articleInfoFormikRef.current?.submitForm()}
        >
          {t('common:actions.save')}
        </Button>
      </div>
    );
  }, [articleInfoFormikRef.current, articleInformationDialogMode]);

  const countryList = useMemo(() => {
    let result: { label: string; value: string }[] = [];
    if (!countries) return [];

    let keys = Object.keys(countries.getAlpha2Codes());
    for (let key of keys) {
      let val = t(`common:countries.${key}`);
      if (val) {
        result.push({ label: val, value: key });
      }
    }
    return result;
  }, [countries]);

  useEffect(() => {
    stockApi.ListUnitTypes().then((x) => setArticleUnitTypes(x ?? []));
  }, [stockApi.ListUnitTypes]);

  const textEditor = useCallback((options: ColumnEditorOptions) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => {
          if (options.editorCallback) options.editorCallback(e.target.value);
        }}
      />
    );
  }, []);

  const dropdownEditor = useCallback(
    (
      options: ColumnEditorOptions,
      ddlOptions: any[] = [],
      ddlOptionLabel?: string,
      ddlOptionValue?: string,
    ) => {
      return (
        <Dropdown
          options={ddlOptions}
          optionLabel={ddlOptionLabel}
          optionValue={ddlOptionValue}
          value={options.value}
          onChange={(e) => {
            if (options.editorCallback) options.editorCallback(e.target.value);
          }}
        />
      );
    },
    [],
  );

  const articleValidationSchema = useMemo(() => {
    const lowerlocale = i18n.language.substring(0, 2).toLowerCase();

    if (yuplocales[lowerlocale]) {
      yup.setLocale(yuplocales[lowerlocale]);
    }

    return yup.object<Omit<StockArticleDto, 'availableUnits'>>({
      articleNumber: yup.string().notRequired().label(t('stock:articleNumber')),
      barcode: yup.string().notRequired().label(t('stock:barcode')),
      brandName: yup.string().notRequired().label(t('stock:brand')),
      categoryId: yup.number().required().min(1).label(t('common:category')),
      countryOfOrigin: yup
        .string()
        .length(2)
        .notRequired()
        .label(t('stock:country-of-origin')),
      description: yup.string().required().label(t('common:description')),
      name: yup.string().required().label(t('common:name')),
      grossWeight: yup
        .number()
        .min(0)
        .notRequired()
        .label(t('stock:gross-weight')),
      netWeight: yup.number().min(0).notRequired().label(t('stock:net-weight')),
      id: yup.number(),
      intrastatCode: yup
        .number()
        .min(0)
        .max(99999999)
        .notRequired()
        .label(t('stock:intrastat-code')),
      maxStockAmount: yup
        .number()
        .min(0)
        .notRequired()
        .label(t('stock:max-stock-amount')),
      safetyStockAmount: yup
        .number()
        .min(0)
        .notRequired()
        .label(t('stock:safety-stock-amount')),
      procurementCost: yup
        .number()
        .min(0)
        .notRequired()
        .label(t('stock:procurement-cost')),
      reorderPoint: yup
        .number()
        .min(0)
        .notRequired()
        .label(t('stock:reorder-point')),
      unit: yup.string().required().label(t('stock:unit-type')),
    });
  }, [i18n.language]);

  const initialArticleValues = useCallback(
    (data?: StockArticleDto) => {
      type returnType = Omit<StockArticleDto, 'availableUnits'>;

      let result: returnType = {
        articleNumber: '',
        barcode: '',
        brandName: '',
        categoryId: Number(selectedCategoryId ?? 0),
        countryOfOrigin: 'NL',
        description: '',
        grossWeight: null,
        id: 0,
        intrastatCode: null,
        maxStockAmount: null,
        name: '',
        netWeight: null,
        procurementCost: null,
        reorderPoint: null,
        safetyStockAmount: null,
        unit: '',
      };

      if (data) {
        const { availableUnits, ...remaining } = data;
        result = {
          ...result,
          ...remaining,
        };
        return result;
      } else {
        return result;
      }
    },
    [selectedCategoryId],
  );

  const initialMutationValues = useCallback(
    (data?: StockArticleDto) => {
      let result = {
        categoryId: 0,
        articleId: 0,
        warehouseId: 0,
        locationId: 0,
        amountMutation: 0,
      };

      if (data) {
        result.amountMutation = 0;
        result.articleId = data.id ?? 0;
        result.categoryId = data.categoryId ?? 0;
      }

      return result;
    },
    [selectedCategoryId],
  );

  const SaveArticleUnitType = useCallback(
    async (data: StockUnitTypeDto) => {
      if (!data || !data.code || !data.classification || !data.description)
        return false;

      let result = false;

      try {
        const fetchedActual = await stockApi.ListUnitTypes();

        if (
          fetchedActual.find(
            (x) => x.code?.toUpperCase() === data.code?.toUpperCase(),
          )
        ) {
          result = await stockApi.UpdateUnitType(data);
        } else {
          result = await stockApi.AddUnitType(data);
        }

        if (result) {
          if (notificationContext) {
            notificationContext.showNotification(`Saved`);
          } else {
            showNotification('Saved');
          }
        } else {
          if (notificationContext) {
            notificationContext.showWarningNotification(`Save failed`);
          } else {
            showNotification('Save failed');
          }
        }
      } catch (e) {
        alert(e instanceof Error ? e.message : String(e));
        result = false;
      }
      return result;
    },
    [stockApi],
  );

  const __processMutation = useCallback(
    async (values: any) => {
      const updated = await stockApi.ProcessArticleInventoryMutation(
        values.articleId,
        values.locationId,
        values.amountMutation,
      );

      if (updated) {
        if (notificationContext) {
          notificationContext.showNotification(
            t('notifications:item-changed-succesfully'),
          );
        } else {
          showNotification(t('notifications:item-changed-succesfully'));
        }

        setShowMutateDialog(false);
        articleInformationDataRef.current = undefined;
      }
    },
    [stockApi.ProcessArticleInventoryMutation],
  );

  return (
    <>
      <Island title={t('stock:categories-and-articles')}>
        <div className="w-full flex flex-column gap-5">
          <div className="w-full flex flex-column sm:flex-row gap-5">
            <div className="w-12 sm:w-6">
              <TreeTable
                paginator
                rows={5}
                header={t('common:categories')}
                value={stockApi.categoryTree}
                selectionMode="single"
                selectionKeys={selectedCategoryId}
                onSelectionChange={(e) => setSelectedCategoryId(e.value)}
                footer={
                  <div className="w-full flex gap-2">
                    <Button
                      icon="pi pi-plus"
                      onClick={async () => await createNewCategory()}
                    />
                    <Button
                      icon="pi pi-minus"
                      disabled={
                        !selectedCategoryId || hasChildren(selectedCategoryId)
                      }
                      onClick={async () => await removeCategory()}
                    />
                  </div>
                }
              >
                <Column
                  sortable
                  field="name"
                  header={t('common:name')}
                  expander
                  body={(data) => {
                    if (data.data.id < 0) {
                      return <em>{data.label} **</em>;
                    }
                    return data.label;
                  }}
                />
              </TreeTable>
            </div>
            <div className="w-12 sm:w-6">
              <Panel
                header={t('stock:category-information')}
                footer={
                  <div className="w-full flex flex-row-reverse">
                    <Button
                      icon="pi pi-save mr-2"
                      disabled={!catInfoChanged}
                      onClick={() => saveEditedCategory()}
                    >
                      {t('common:actions.save')}
                    </Button>
                  </div>
                }
              >
                <div className="w-full flex flex-column gap-2">
                  <div className="w-full flex flex-row gap-2 align-items-center">
                    <label className="w-12 sm:w-4" htmlFor="catId">
                      {t('common:id')}
                    </label>
                    <InputNumber
                      readOnly
                      disabled
                      className="w-12 sm:w-8"
                      id="catId"
                      value={currentCategory?.id}
                    />
                  </div>
                  <div className="w-full flex flex-row gap-2 align-items-center">
                    <label className="w-12 sm:w-4" htmlFor="catName">
                      {t('common:name')}
                    </label>
                    <InputText
                      className="w-12 sm:w-8"
                      id="catName"
                      value={currentCategory?.name ?? ''}
                      onChange={(e) =>
                        setCurrentCategory((old) => {
                          return {
                            ...old,
                            name: e.target.value,
                          };
                        })
                      }
                    />
                  </div>
                  <div className="w-full flex flex-row gap-2 align-items-center">
                    <label className="w-12 sm:w-4" htmlFor="catDesc">
                      {t('common:description')}
                    </label>
                    <InputText
                      className="w-12 sm:w-8"
                      id="catDesc"
                      value={currentCategory?.description ?? ''}
                      onChange={(e) =>
                        setCurrentCategory((old) => {
                          return {
                            ...old,
                            description: e.target.value,
                          };
                        })
                      }
                    />
                  </div>
                </div>
              </Panel>
            </div>
          </div>
          <div className="w-full flex flex-column">
            <div className="w-full">
              <DataTable
                header={
                  <div className="w-full flex flex-column">
                    <div className="w-full">{t('common:articles')}</div>
                    <div className="w-full mt-2 py-2 px-0 flex flex-row gap-2">
                      <Button
                        className="p-2"
                        tooltip="Add"
                        icon="pi pi-plus"
                        onClick={() => {
                          articleInformationDataRef.current = undefined;
                          setArticleInformationDialogMode('EDIT');
                          setShowArticleInformationDialog(true);
                        }}
                      />
                      <Button
                        disabled={
                          Array.isArray(selectedArticles)
                            ? selectedArticles.length == 0
                            : !selectedArticles
                        }
                        className="p-2"
                        tooltip={t('stock:move-articles-to-category')}
                        onClick={async () => {
                          setShowMoveCategoryDialog(true);
                        }}
                      >
                        <i className="pi pi-angle-right"></i>
                        <i className="pi pi-folder-open"></i>
                      </Button>
                    </div>
                  </div>
                }
                rows={15}
                paginator
                emptyMessage={t('common:no-items')}
                value={articles}
                selectionMode="checkbox"
                onSelectionChange={(e) =>
                  setSelectedArticles(e.value as StockArticleDto[])
                }
                selection={
                  Array.isArray(selectedArticles)
                    ? selectedArticles
                    : selectedArticles !== undefined &&
                      selectedArticles !== null
                    ? [selectedArticles]
                    : []
                }
              >
                <Column
                  selectionMode="multiple"
                  headerStyle={{ width: '3rem' }}
                ></Column>
                <Column
                  field="articleNumber"
                  sortable
                  filter
                  header={t('stock:articleNumber')}
                />
                <Column
                  field="brandName"
                  sortable
                  filter
                  header={t('stock:brand')}
                />
                <Column
                  field="name"
                  sortable
                  filter
                  header={t('common:name')}
                />
                <Column field="availableUnits" header={t('common:amount')} />
                <Column field="unit" header={t('stock:unit-type')} />
                <Column
                  field="id"
                  className="w-1"
                  body={(data, options) => {
                    return (
                      <div className="w-full flex flex-row gap-2">
                        <Button
                          icon="pi pi-info-circle"
                          rounded
                          outlined
                          onClick={() => {
                            articleInformationDataRef.current = data;
                            setArticleInformationDialogMode('VIEW');
                            setShowArticleInformationDialog(true);
                          }}
                          tooltip="show info"
                        />
                        <Button
                          rounded
                          outlined
                          icon={<PlusMinus size={24} />}
                          tooltip="mutations"
                          onClick={async () => {
                            articleInformationDataRef.current = data;

                            const arts =
                              await stockApi.ListArticlesForCategoryId(
                                articleInformationDataRef.current?.categoryId,
                              );
                            const locs =
                              await stockApi.GetAvailableArticleLocations(
                                articleInformationDataRef.current?.id,
                              );

                            setMutationDialogArticles(arts);
                            setMutationDialogArticleLocations(locs);

                            setShowMutateDialog(true);
                          }}
                        />
                      </div>
                    );
                  }}
                />
              </DataTable>
            </div>
          </div>
        </div>
      </Island>
      <MutateStockDialog
        onHide={() => {
          setShowMutateDialog(false);
          articleInformationDataRef.current = undefined;
        }}
        visible={showMutateDialog}
        articleId={articleInformationDataRef.current?.id}
        onSave={async (values) => {
          await __processMutation(values);
          let numCat = Number(selectedCategoryId);
          if (!isNaN(numCat))
            setArticles(await stockApi.ListArticlesForCategoryId(numCat, true))
        }}
      />

      <Dialog
        className="w-12 sm:w-4"
        onHide={() => {
          setShowMoveCategoryDialog(false);
        }}
        visible={showMoveCategoryDialog}
        modal
        header={t('stock:dialog.move-to-category.move-to-category', {
          replace: {
            count: Array.isArray(selectedArticles)
              ? selectedArticles.length
              : selectedArticles
              ? 1
              : 0,
          },
        })}
      ></Dialog>
      <Dialog
        className="w-12 sm:w-6"
        visible={showArticleInformationDialog}
        onHide={() => setShowArticleInformationDialog(false)}
        modal
        closeOnEscape
        header={
          <div className="w-full flex flex-row gap-2 align-items-center justify-content-between">
            <div>{t('stock:article-information')}</div>
            <div className="toolbar flex flex-row gap-2">
              <Button
                icon="pi pi-copy"
                onClick={async () => {
                  await articleInfoFormikRef.current?.setFieldValue('id', 0);
                  setArticleInformationDialogMode('EDIT');
                }}
              />

              <Button
                icon={
                  articleInformationDialogMode == 'VIEW'
                    ? 'pi pi-pencil'
                    : 'pi pi-eye'
                }
                onClick={() => {
                  setArticleInformationDialogMode((old) =>
                    old == 'EDIT' ? 'VIEW' : 'EDIT',
                  );
                }}
              />

              <Button
                className="bg-red-400 border-red-300"
                icon={<FaTrashAlt />}
                onClick={async () => {
                  if (articleInformationDataRef.current?.id) {
                    if (
                      confirm(
                        'Artikel verwijderen? Let op! artikelen kunnen in BOM templates gebruikt zijn waardoor scope onstabiel kan worden.',
                      )
                    ) {
                      const deleted = await stockApi.DeleteArticle(
                        articleInformationDataRef.current?.id,
                      );

                      if (deleted) {
                        if (notificationContext) {
                          notificationContext.showNotification(
                            t('notifications:item-succesfully-removed'),
                          );
                        } else {
                          showNotification(
                            t('notifications:item-succesfully-removed'),
                          );
                        }
                        fetchArticles(Number(selectedCategoryId));
                        setShowArticleInformationDialog(false);
                        articleInformationDataRef.current = undefined;
                      }
                    }
                  }
                }}
              />
            </div>
          </div>
        }
        footer={articleInfoFooter}
      >
        <Formik
          initialValues={initialArticleValues(
            articleInformationDataRef.current,
          )}
          validationSchema={articleValidationSchema}
          innerRef={articleInfoFormikRef}
          onSubmit={(values, helpers) => {
            helpers.setSubmitting(true);
            let v: StockArticleDto = values;
            stockApi
              .AddOrUpdateArticle(v)
              .then((data) => {
                if (data === true) {
                  if (notificationContext) {
                    notificationContext.showNotification(`Success`);
                  } else {
                    showErrorNotification(`Success`);
                  }
                  setShowArticleInformationDialog(false);
                  fetchArticles(Number(selectedCategoryId));
                  articleInformationDataRef.current = undefined;
                } else {
                  if (notificationContext) {
                    notificationContext.showWarningNotification(
                      `${v.id ? 'update' : 'addition'} failed`,
                    );
                  } else {
                    showWarningNotification(
                      `${v.id ? 'update' : 'addition'} failed`,
                    );
                  }
                }
              })
              .catch((err) => {
                console.error(err);
                if (notificationContext) {
                  notificationContext.showErrorNotification(
                    `Unable to process ${
                      v.id ? 'update' : 'add'
                    } request due to exception`,
                  );
                } else {
                  showErrorNotification(
                    `Unable to process ${
                      v.id ? 'update' : 'add'
                    } request due to exception`,
                  );
                }
              })
              .finally(() => {
                helpers.setSubmitting(false);
              });
          }}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit}>
              <div className="formgrid grid">
                <div className="form-group col-12 sm:col-3">
                  <label htmlFor="id" className="form-label">
                    {t('common:id')}
                  </label>
                  <InputText id="id" {...props.getFieldProps('id')} disabled />
                </div>
                <div className="form-group col-12 sm:col-6 sm:col-offset-3">
                  <label htmlFor="categoryId" className="form-label">
                    {t('common:category')}
                  </label>
                  <TreeSelect
                    className="w-full"
                    style={{ padding: '0.18rem' }}
                    options={stockApi.categoryTree}
                    id="categoryId"
                    disabled={articleInformationDialogMode == 'VIEW'}
                    value={String(props.values.categoryId ?? 0)}
                    onChange={(e) => {
                      props.setFieldValue('categoryId', e.value);
                    }}
                  />
                  {props.errors.categoryId && (
                    <small className="danger">{props.errors.categoryId}</small>
                  )}
                </div>

                <div className="form-group col-12 sm:col-6">
                  <label htmlFor="name" className="form-label">
                    {t('common:name')}
                  </label>
                  <InputText
                    id="name"
                    {...props.getFieldProps('name')}
                    disabled={articleInformationDialogMode == 'VIEW'}
                  />
                  {props.errors.name && (
                    <small className="danger">{props.errors.name}</small>
                  )}
                </div>
                <div className="form-group col-12 sm:col-6">
                  <label htmlFor="description" className="form-label">
                    {t('common:description')}
                  </label>
                  <InputText
                    id="description"
                    {...props.getFieldProps('description')}
                    disabled={articleInformationDialogMode == 'VIEW'}
                  />
                  {props.errors.description && (
                    <small className="danger">{props.errors.description}</small>
                  )}
                </div>

                <div className="form-group col-12 sm:col-6">
                  <label htmlFor="brandName" className="form-label">
                    {t('stock:brand')}
                  </label>
                  <InputText
                    id="brandName"
                    {...props.getFieldProps('brandName')}
                    disabled={articleInformationDialogMode == 'VIEW'}
                  />
                  {props.errors.brandName && (
                    <small className="danger">{props.errors.brandName}</small>
                  )}
                </div>
                <div className="form-group col-12 sm:col-6">
                  <label htmlFor="countryOfOrigin" className="form-label">
                    {t('stock:country-of-origin')}
                  </label>
                  <Dropdown
                    options={countryList}
                    optionLabel="label"
                    optionValue="value"
                    id="countryOfOrigin"
                    disabled={articleInformationDialogMode == 'VIEW'}
                    value={props.values.countryOfOrigin ?? ''}
                    onChange={(e) => {
                      props.setFieldValue('countryOfOrigin', e.value);
                    }}
                  />
                  {props.errors.countryOfOrigin && (
                    <small className="danger">
                      {props.errors.countryOfOrigin}
                    </small>
                  )}
                </div>
                <div className="form-group col-12 sm:col-3">
                  <label htmlFor="articleNumber" className="form-label">
                    {t('stock:articleNumber')}
                  </label>
                  <InputText
                    id="articleNumber"
                    {...props.getFieldProps('articleNumber')}
                    disabled={articleInformationDialogMode == 'VIEW'}
                  />
                  {props.errors.articleNumber && (
                    <small className="danger">
                      {props.errors.articleNumber}
                    </small>
                  )}
                </div>
                <div className="form-group col-12 sm:col-3">
                  <label htmlFor="barcode" className="form-label">
                    {t('stock:barcode')}
                  </label>
                  <InputText
                    id="barcode"
                    {...props.getFieldProps('barcode')}
                    disabled={articleInformationDialogMode == 'VIEW'}
                  />
                  {props.errors.barcode && (
                    <small className="danger">{props.errors.barcode}</small>
                  )}
                </div>
                <div className="form-group col-6 sm:col-3">
                  <label htmlFor="grossWeight" className="form-label">
                    {t('stock:gross-weight')}
                  </label>
                  <InputNumber
                    className="w-full"
                    id="grossWeight"
                    min={0}
                    step={0.001}
                    minFractionDigits={0}
                    maxFractionDigits={5}
                    {...props.getFieldProps('grossWeight')}
                    disabled={articleInformationDialogMode == 'VIEW'}
                    onChange={(e) =>
                      props.getFieldHelpers('grossWeight').setValue(e.value)
                    }
                  />
                  {props.errors.grossWeight && (
                    <small className="danger">{props.errors.grossWeight}</small>
                  )}
                </div>
                <div className="form-group col-6 sm:col-3">
                  <label htmlFor="netWeight" className="form-label">
                    {t('stock:net-weight')}
                  </label>
                  <InputNumber
                    id="netWeight"
                    className="w-full"
                    minFractionDigits={0}
                    maxFractionDigits={5}
                    min={0}
                    step={0.00001}
                    {...props.getFieldProps('netWeight')}
                    disabled={articleInformationDialogMode == 'VIEW'}
                    onChange={(e) =>
                      props.getFieldHelpers('netWeight').setValue(e.value)
                    }
                  />
                  {props.errors.netWeight && (
                    <small className="danger">{props.errors.netWeight}</small>
                  )}
                </div>

                <div className="form-group col-6 sm:col-3">
                  <label htmlFor="procurementCost" className="form-label">
                    {t('stock:procurement-cost')}
                  </label>
                  <InputNumber
                    mode="currency"
                    currency="EUR"
                    id="procurementCost"
                    minFractionDigits={0}
                    maxFractionDigits={5}
                    step={0.01}
                    className="w-full"
                    {...props.getFieldProps('procurementCost')}
                    disabled={articleInformationDialogMode == 'VIEW'}
                    onChange={(e) =>
                      props.getFieldHelpers('procurementCost').setValue(e.value)
                    }
                  />
                  {props.errors.procurementCost && (
                    <small className="danger">
                      {props.errors.procurementCost}
                    </small>
                  )}
                </div>
                <div className="form-group col-6 sm:col-3">
                  <label htmlFor="unit" className="form-label">
                    {t('stock:unit-type')}
                  </label>
                  <div className="flex flex-row">
                    <Dropdown
                      options={articleUnitTypes}
                      optionLabel="description"
                      optionValue="code"
                      value={props.values.unit}
                      onChange={(e) => props.setFieldValue('unit', e.value)}
                      onBlur={(e) => props.handleBlur(e)}
                      disabled={articleInformationDialogMode == 'VIEW'}
                    />
                    <Button
                      icon="pi pi-pencil"
                      tooltip="edit unit types"
                      onClick={() => OpenEditUnitTypes()}
                    />
                  </div>
                  {props.errors.unit && (
                    <small className="danger">{props.errors.unit}</small>
                  )}
                </div>
                <div className="form-group col-12 sm:col-6">
                  <label htmlFor="intrastatCode" className="form-label">
                    {t('stock:intrastat-code')}
                  </label>
                  <InputNumber
                    id="intrastatCode"
                    className="w-full"
                    {...props.getFieldProps('intrastatCode')}
                    min={0}
                    max={99999999}
                    maxLength={8}
                    disabled={articleInformationDialogMode == 'VIEW'}
                    onChange={(e) =>
                      props.getFieldHelpers('intrastatCode').setValue(e.value)
                    }
                  />
                  {props.errors.intrastatCode && (
                    <small className="danger">
                      {props.errors.intrastatCode}
                    </small>
                  )}
                </div>
              </div>
              <div className="formgrid grid">
                <div className="form-group col-6 sm:col-3">
                  <label htmlFor="maxStockAmount" className="form-label">
                    {t('stock:max-stock-amount')}
                  </label>
                  <InputNumber
                    id="maxStockAmount"
                    className="w-full"
                    {...props.getFieldProps('maxStockAmount')}
                    min={0}
                    max={99999999}
                    onChange={(e) =>
                      props.getFieldHelpers('maxStockAmount').setValue(e.value)
                    }
                    disabled={articleInformationDialogMode == 'VIEW'}
                  />
                  {props.errors.maxStockAmount && (
                    <small className="danger">
                      {props.errors.maxStockAmount}
                    </small>
                  )}
                </div>
                <div className="form-group col-6 sm:col-3">
                  <label htmlFor="safetyStockAmount" className="form-label">
                    {t('stock:safety-stock-amount')}
                  </label>
                  <InputNumber
                    id="safetyStockAmount"
                    className="w-full"
                    {...props.getFieldProps('safetyStockAmount')}
                    min={0}
                    max={99999999}
                    minFractionDigits={0}
                    maxFractionDigits={3}
                    disabled={articleInformationDialogMode == 'VIEW'}
                    onChange={(e) =>
                      props
                        .getFieldHelpers('safetyStockAmount')
                        .setValue(e.value)
                    }
                  />
                  {props.errors.safetyStockAmount && (
                    <small className="danger">
                      {props.errors.safetyStockAmount}
                    </small>
                  )}
                </div>
                <div className="form-group col-6 sm:col-3">
                  <label htmlFor="reorderPoint" className="form-label">
                    {t('stock:reorder-point')}
                  </label>
                  <InputNumber
                    id="reorderPoint"
                    className="w-full"
                    {...props.getFieldProps('reorderPoint')}
                    min={0}
                    max={99999999}
                    minFractionDigits={0}
                    maxFractionDigits={3}
                    disabled={articleInformationDialogMode == 'VIEW'}
                    onChange={(e) =>
                      props.getFieldHelpers('reorderPoint').setValue(e.value)
                    }
                  />
                  {props.errors.reorderPoint && (
                    <small className="danger">
                      {props.errors.reorderPoint}
                    </small>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Dialog>
      <Dialog
        className="w-12 sm:w-4"
        closeOnEscape
        modal
        visible={showUnitTypesEditDialog}
        onHide={() => {
          setShowUnitTypesEditDialog(false);
        }}
        header={t('stock:dialog.edit-unittypes.edit-unittypes')}
      >
        <DataTable
          rows={5}
          editMode="row"
          onRowEditCancel={(e) => {
            if (!e.data.code) {
              setArticleUnitTypes2((old) => old.filter((d, i) => i != e.index));
            }
          }}
          onRowEditSave={async (e) => {
            if (!e.newData.code) {
              if (notificationContext) {
                notificationContext.showWarningNotification('Missing code');
                e.valid = false;
              }
            } else if (!e.newData.description) {
              if (notificationContext) {
                notificationContext.showWarningNotification(
                  'Missing description',
                );
                e.valid = false;
              }
            }

            if (e.valid) {
              let saved = await SaveArticleUnitType(
                e.newData as StockUnitTypeDto,
              );
              if (saved) {
                let newTypes = await stockApi.ListUnitTypes();
                setArticleUnitTypes(newTypes);
                setArticleUnitTypes2(newTypes);
              }
            }
          }}
          paginator
          value={articleUnitTypes2}
          header={
            <div className="w-full flex flex-row-reverse">
              <Button
                icon="pi pi-plus"
                onClick={() =>
                  setArticleUnitTypes2((old) => [
                    ...old,
                    { classification: 'Other', code: '', description: '' },
                  ])
                }
              />
            </div>
          }
        >
          <Column header="Code" editor={textEditor} field="code" />
          <Column
            header="Class"
            editor={(o) =>
              dropdownEditor(
                o,
                Object.values(ArticleUnitClassificationEnum).filter(
                  (value) => typeof value === 'string',
                ) as string[],
              )
            }
            field="classification"
          />
          <Column
            editor={textEditor}
            header={t('common:description')}
            field="description"
          />
          <Column rowEditor />
        </DataTable>
      </Dialog>
    </>
  );
};

export default CategoriesAndArticlesPage;
