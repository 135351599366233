import React from 'react';
import { RouteConfig, RouteConfigComponentProps } from 'react-router-config';
import App from '../App';
import {
  createLoginPath,
  createHomePath,
  createTrengoPath,
  createNotificationsPath,
  createOrdersDetailPath,
  createTenantsPath,
  createTenantsDetailPath,
  createTenantsNewPath,
  createFormsPath,
  createFormsDetailPath,
  createFormsNewPath,
  createUsersPath,
  createUsersDetailPath,
  createUsersNewPath,
  createShoppingPath,
  createShoppingNewPath,
  createProductsPath,
  createMarketingPath,
  createProductsNewPath,
  createOrdersNewPath,
  createOrdersAdjustWorkPath,
  createArticlesPath,
  createArticlesDetailPath,
  createArticlesNewPath,
  createCategoriesPath,
  createCategoriesDetailPath,
  createCategoriesNewPath,
  createArticlesAmountMutationPath,
  createProductsDetailPath,
  createCustomersPath,
  createCustomerCreatePath,
  createCustomersDetailPath,
  createStatisticsPath,
  createStatisticsWorkingHoursPath,
  createPlanningOverviewPath,
  createOrdersDeliveryPath,
  createSalesMarginsStatisticsPath,
  createOrdersInvoicePath,
  createOrdersDeliveryDetailPath,
  createUsersViewPath,
  createAVPath,
  createConfigurationProcessEmailsPath,
  createConfigurationTenantSettingsPath,
  createSearchPath,
  createNewTaskPath,
  createTaskPath,
  createUpdateTaskPath,
  createWorkInProgressPath,
  createCopyOrderPath,
  createOrdersDeliveryCreateRoutePath,
  createUsersFactoryRate,
  createExpeditionPath,
  createOrderSchedulePath,
  createUserRateDetails,
  createStockManagementPath,
  createProductsBOMTemplatePath,
  // createOrdersDetailNewPath,
} from './appUrlGenerator';
import { lazyWithRetry } from './lazyWithRetry';
import { reduxStore } from '..';
import {
  CUSTOMER_USER,
  OFFICE_USER,
  ADMIN_USER,
  FACTORY_USER,
  SYSTEM_ADMIN,
} from '../hooks/useAuth';
import ErrorNotFound from '../components/core/errorBoundary/ErrorNotFound';
import CreateDeliveryPageV2 from '../pages/statistics/delivery/CreatePageV2';
import TermsAndConditionsOverview from '../pages/termsandconditions';
import LoadingSplash from '../components/shared/LoadingSplash';
import { useTheme } from '../hooks/useTheme';
import ExpeditionPage from '../pages/logistics/expeditionPage';
import StockManagementPage from '../pages/stock2';
import FinancialOverviewPage from '../pages/statistics/financialOverview';
import { BOMTemplatePage } from '../pages/products/BOMTemplatePage';
import CandAPage from '../pages/stock2/categoriesandarticles';

const DashboardPage = lazyWithRetry(() => import('../pages/dashboard/index'));

const DeliveryIndex = lazyWithRetry(
  () => import('../pages/statistics/delivery/DeliveriesIndex'),
);

const Trengo = lazyWithRetry(() => import('../pages/home/trengo'));
const Login = lazyWithRetry(() => import('../pages/authentication/login'));
const Forbidden = lazyWithRetry(
  () => import('../pages/authentication/forbidden'),
);

const Notifications = lazyWithRetry(
  () => import('../pages/notifications/index'),
);

const HomeNew = lazyWithRetry(() => import('../pages/home/index2'));

const Tenants = lazyWithRetry(() => import('../pages/tenants'));
const TenantsDetails = lazyWithRetry(
  () => import('../pages/tenants/DetailsPage'),
);

const Forms = lazyWithRetry(() => import('../pages/forms'));
const FormsDetail = lazyWithRetry(() => import('../pages/forms/CreatePage'));

const Users = lazyWithRetry(() => import('../pages/users'));
const UsersDetail = lazyWithRetry(() => import('../pages/users/DetailsPage'));
const UsersView = lazyWithRetry(() => import('../pages/users/ViewPage'));

const Shopping = lazyWithRetry(() => import('../pages/shopping/index'));
const ShoppingDetail = lazyWithRetry(
  () => import('../pages/shopping/DetailsPage'),
);

const OrdersDetail = lazyWithRetry(() => import('../pages/order/DetailPage'));
// const OrdersDetail2 = lazyWithRetry(() => import('../pages/order/DetailPagev2'));
const OrdersCreate = lazyWithRetry(() => import('../pages/order/CreatePage'));
const OrdersCreateV2 = lazyWithRetry(
  () => import('../pages/order/CreatePageV2'),
);
const OrdersAdjustWork = lazyWithRetry(
  () => import('../pages/order/ModifyWorkedHoursV2'),
);

const OrdersDelivery = lazyWithRetry(
  () => import('../pages/statistics/delivery'),
);
const OrdersInvoice = lazyWithRetry(() => import('../pages/order/InvoicePage'));
const OrderCopy = lazyWithRetry(() => import('../pages/order/CopyPage'));

const WorkInProgress = lazyWithRetry(() => import('../pages/order/InProgress'));

const Products = lazyWithRetry(() => import('../pages/products'));
const ProductsDetail = lazyWithRetry(
  () => import('../pages/products/CreatePage'),
);

const Marketing = lazyWithRetry(() => import('../pages/products/Marketing'));

const Articles = lazyWithRetry(() => import('../pages/stock/articles'));
const ArticlesDetail = lazyWithRetry(
  () => import('../pages/stock/articles/DetailPage'),
);
const ArticlesAmountMutation = lazyWithRetry(
  () => import('../pages/stock/articles/AmountMutation'),
);

const Categories = lazyWithRetry(() => import('../pages/stock/categories'));
const CategoriesDetail = lazyWithRetry(
  () => import('../pages/stock/categories/DetailPage'),
);

const Customers = lazyWithRetry(() => import('../pages/customers'));
const CustomersDetail = lazyWithRetry(
  () => import('../pages/customers/DetailPage'),
);
const CustomersAdd = lazyWithRetry(() => import('../pages/customers/AddPage'));

const Statistics = lazyWithRetry(() => import('../pages/statistics/index'));
const StatisticsWorkingHours = lazyWithRetry(
  () => import('../pages/statistics/workingHours'),
);

const Planning = lazyWithRetry(
  () => import('../pages/statistics/workPlanning'),
);
const SalesMarginStatistics = lazyWithRetry(
  () => import('../pages/statistics/salesMargins'),
);

const ConfigurationProcessEmailsIndex = lazyWithRetry(
  () => import('../pages/configuration/processemails/index'),
);

const TenantSettings = lazyWithRetry(
  () => import('../pages/configuration/tenantSettings'),
);
const TenantSettings2 = lazyWithRetry(
  () => import('../pages/configuration/tenantSettings2'),
);

const TaskManagmentIndex = lazyWithRetry(
  () => import('../pages/configuration/tasks/index'),
);
const TaskDetails = lazyWithRetry(
  () => import('../pages/configuration/tasks/detailsPage'),
);

const SearchPage = lazyWithRetry(() => import('../pages/home/search'));

const OrdersDeliveryPlanRoutePage = lazyWithRetry(
  () => import('../pages/statistics/delivery/PlanRoute'),
);

const DeliveryAcknowledgePage = lazyWithRetry(
  () => import('../pages/statistics/delivery/DeliveryAcknowledgePage'),
);

const DeliveryAcknowledgePageNoCtx = lazyWithRetry(
  () => import('../pages/statistics/delivery/DeliveryAcknowledgePageNoCtx'),
);

const PerFactoryUserRate = lazyWithRetry(
  () => import('../pages/users/DynamicUserRate'),
);

const PerFactoryUserRateDetails = lazyWithRetry(
  () => import('../pages/users/DynamicUserRateDetails'),
);

const OrderSchedulePage = lazyWithRetry(
  () => import('../pages/planning/OrderSchedulePage'),
);

const BomTestPage = lazyWithRetry(() => import('../pages/bomtest'));

const renderComponentIfAllowed = (
  props: RouteConfigComponentProps,
): React.ReactNode => {
  const state = reduxStore.getState();
  const { auth } = state;
  const Component = props.route ? props.route.component : null;

  function userIsAllowed() {
    if (!auth.userDbData) {
      return false;
    }

    const userrole = auth.userDbData?.userrole;
    const customRole = auth.userDbData['custom:role'];
    const allowedRoles = props.route?.allowedRoles;

    if (allowedRoles?.includes(SYSTEM_ADMIN) && customRole === SYSTEM_ADMIN) {
      return true;
    }

    return (
      allowedRoles?.filter(
        (r: string) => r.toLowerCase() === userrole?.toLowerCase(),
      ).length > 0
    );
  }

  if (userIsAllowed() && Component) {
    return <Component {...props} />;
  } else {
    return null;
  }

  //  return <Redirect to={createHomePath()} />;
};

const routes: RouteConfig[] = [
  { path: '/forbidden', component: Forbidden, exact: true },
  {
    path: '/loader',
    component: () => {
      useTheme();
      return <LoadingSplash />;
    },
    exact: true,
  },
  {
    path: '/delivery/ack/:token',
    component: DeliveryAcknowledgePageNoCtx,
    exact: true,
  },
  {
    path: createLoginPath(),
    component: Login,
    exact: true,
  },
  {
    path: createTrengoPath(),
    component: Trengo,
    exact: true,
  },
  {
    component: App,
    routes: [
      { path: '/bomtest', component: BomTestPage, exact: true },

      {
        path: createHomePath(),
        component: HomeNew,
        exact: true,
      },
      {
        path: `/dashboard`,
        component: DashboardPage,
        exact: true,
        render: renderComponentIfAllowed,
        allowedRoles: [ADMIN_USER, OFFICE_USER],
      },

      {
        path: createNotificationsPath(),
        component: Notifications,
        exact: true,
      },
      // {
      //   path: `${createOrdersNewPath()}/v2`,
      //   exact: true,
      //   component: OrdersCreateV2,
      //   render: renderComponentIfAllowed,
      //   allowedRoles: [ADMIN_USER, OFFICE_USER],
      // },
      {
        path: createOrdersNewPath(),
        exact: true,
        component: OrdersCreate,
        render: renderComponentIfAllowed,
        allowedRoles: [ADMIN_USER, OFFICE_USER],
      },
      {
        path: '/delivery/acknowledge/:token',
        component: DeliveryAcknowledgePage,
        exact: true,
      },
      {
        path: `${createOrdersDeliveryPath()}/old`,
        render: renderComponentIfAllowed,
        exact: true,
        component: OrdersDelivery,
        allowedRoles: [ADMIN_USER, OFFICE_USER, FACTORY_USER],
      },
      {
        path: createOrdersDeliveryPath(),
        render: renderComponentIfAllowed,
        exact: true,
        component: DeliveryIndex,
        allowedRoles: [ADMIN_USER, OFFICE_USER, FACTORY_USER],
      },
      {
        path: createOrdersDeliveryCreateRoutePath(),
        render: renderComponentIfAllowed,
        exact: true,
        component: OrdersDeliveryPlanRoutePage,
        allowedRoles: [ADMIN_USER, OFFICE_USER],
      },
      {
        path: createOrdersDeliveryDetailPath(),
        render: renderComponentIfAllowed,
        exact: true,
        component: CreateDeliveryPageV2,
        allowedRoles: [ADMIN_USER, OFFICE_USER, FACTORY_USER],
      },

      {
        path: createOrdersInvoicePath(),
        render: renderComponentIfAllowed,
        exact: true,
        component: OrdersInvoice,
        allowedRoles: [ADMIN_USER, OFFICE_USER],
      },
      {
        path: createWorkInProgressPath(),
        exact: true,
        render: renderComponentIfAllowed,
        component: WorkInProgress,
        allowedRoles: [ADMIN_USER, OFFICE_USER],
      },
      {
        path: createCopyOrderPath(),
        render: renderComponentIfAllowed,
        component: OrderCopy,
        allowedRoles: [ADMIN_USER, OFFICE_USER],
        exact: true,
      },

      // Schedule
      {
        exact: true,
        path: createOrderSchedulePath(),
        component: OrderSchedulePage,
        render: renderComponentIfAllowed,
        allowedRoles: [OFFICE_USER, ADMIN_USER],
      },
      // End of schedule

      // {
      //   path: createOrdersDetailNewPath(),
      //   render: renderComponentIfAllowed,
      //   component: OrdersDetail2,
      //   allowedRoles: [ADMIN_USER, FACTORY_USER, OFFICE_USER, CUSTOMER_USER],
      //   exact:true
      // },
      {
        path: createOrdersDetailPath(),
        render: renderComponentIfAllowed,
        component: OrdersDetail,
        allowedRoles: [ADMIN_USER, FACTORY_USER, OFFICE_USER, CUSTOMER_USER],
        exact: true,
      },

      {
        path: createOrdersAdjustWorkPath(),
        render: renderComponentIfAllowed,
        component: OrdersAdjustWork,
        allowedRoles: [ADMIN_USER, OFFICE_USER],
      },
      {
        path: createTenantsPath(),
        component: Tenants,
        exact: true,
        render: renderComponentIfAllowed,
        allowedRoles: [ADMIN_USER, SYSTEM_ADMIN],
      },
      {
        path: createTenantsNewPath(),
        exact: true,
        component: (props: RouteConfigComponentProps) => (
          <TenantsDetails mode="create" {...props} />
        ),
        render: renderComponentIfAllowed,
        allowedRoles: [ADMIN_USER, SYSTEM_ADMIN],
      },
      {
        path: createTenantsDetailPath(),
        component: (props: RouteConfigComponentProps) => (
          <TenantsDetails mode="edit" {...props} />
        ),
        render: renderComponentIfAllowed,
        allowedRoles: [ADMIN_USER, SYSTEM_ADMIN],
      },
      {
        path: createFormsPath(),
        component: Forms,
        exact: true,
        render: renderComponentIfAllowed,
        allowedRoles: [ADMIN_USER, OFFICE_USER],
      },
      {
        path: createFormsNewPath(),
        exact: true,
        component: (props: any) => <FormsDetail mode="create" {...props} />,
        render: renderComponentIfAllowed,
        allowedRoles: [ADMIN_USER, OFFICE_USER],
      },
      {
        path: createFormsDetailPath(),
        component: (props: any) => <FormsDetail mode={'edit'} {...props} />,
        render: renderComponentIfAllowed,
        allowedRoles: [ADMIN_USER, OFFICE_USER],
      },
      {
        path: createUsersPath(),
        component: Users,
        exact: true,
        render: renderComponentIfAllowed,
        allowedRoles: [ADMIN_USER, OFFICE_USER],
      },
      {
        path: createUserRateDetails(),
        exact: true,
        render: renderComponentIfAllowed,
        allowedRoles: [ADMIN_USER, OFFICE_USER],
        component: PerFactoryUserRateDetails,
      },
      {
        path: createUsersFactoryRate(),
        component: PerFactoryUserRate,
        exact: true,
        render: renderComponentIfAllowed,
        allowedRoles: [ADMIN_USER, OFFICE_USER],
      },
      {
        path: createUsersNewPath(),
        exact: true,
        component: (props: any) => <UsersDetail mode="create" {...props} />,
        render: renderComponentIfAllowed,
        allowedRoles: [ADMIN_USER, OFFICE_USER],
      },
      {
        path: createUsersDetailPath(),
        component: (props: any) => <UsersDetail mode="edit" {...props} />,
        render: renderComponentIfAllowed,
        allowedRoles: [ADMIN_USER, OFFICE_USER],
      },
      {
        path: createUsersViewPath(),
        component: UsersView,
        render: renderComponentIfAllowed,
        allowedRoles: [ADMIN_USER, OFFICE_USER],
      },
      {
        path: createShoppingPath(),
        // @ts-ignore
        component: Shopping,
        exact: true,
        render: renderComponentIfAllowed,
        allowedRoles: [OFFICE_USER, FACTORY_USER, ADMIN_USER],
      },
      {
        path: createShoppingNewPath(),
        exact: true,
        component: (props: any) => <ShoppingDetail mode="create" {...props} />,
        render: renderComponentIfAllowed,
        allowedRoles: [OFFICE_USER, FACTORY_USER, ADMIN_USER],
      },
      {
        path: createProductsPath(),
        component: Products,
        exact: true,
        render: renderComponentIfAllowed,
        allowedRoles: [ADMIN_USER, OFFICE_USER],
      },
      {
        path: createProductsBOMTemplatePath(),
        component: BOMTemplatePage,
        render: renderComponentIfAllowed,
        allowedRoles: [ADMIN_USER, OFFICE_USER,FACTORY_USER],
        exact:true
      },
      {
        path: createProductsNewPath(),
        exact: true,
        component: (props: any) => <ProductsDetail mode="create" {...props} />,
        render: renderComponentIfAllowed,
        allowedRoles: [ADMIN_USER, OFFICE_USER],
      },
      {
        path: createProductsDetailPath(),
        component: (props: any) => <ProductsDetail mode="edit" {...props} />,
        render: renderComponentIfAllowed,
        allowedRoles: [ADMIN_USER, OFFICE_USER],
      },
      
      {
        path: createMarketingPath(),
        component: Marketing,
        exact: true,
        render: renderComponentIfAllowed,
        allowedRoles: [ADMIN_USER, OFFICE_USER],
      },
      {
        path: createArticlesPath(),
        exact: true,
        component: Articles,
        render: renderComponentIfAllowed,
        allowedRoles: [OFFICE_USER, FACTORY_USER, ADMIN_USER],
      },
      {
        path: createArticlesAmountMutationPath(),
        component: ArticlesAmountMutation,
        render: renderComponentIfAllowed,
        allowedRoles: [OFFICE_USER, FACTORY_USER, ADMIN_USER],
      },
      // REGION NEW STOCK MANAGEMENT
      {
        path: createStockManagementPath(),
        component: StockManagementPage,
        render: renderComponentIfAllowed,
        allowedRoles: [OFFICE_USER, FACTORY_USER, ADMIN_USER],
        exact:true
      },
      {
        path: `${createStockManagementPath()}/c-and-a`,
        component: CandAPage,
        render: renderComponentIfAllowed,
        allowedRoles: [OFFICE_USER, FACTORY_USER, ADMIN_USER]
      },
      // END
      {
        path: createArticlesDetailPath(),
        component: (props: RouteConfigComponentProps) => (
          <ArticlesDetail mode="edit" {...props} />
        ),
        render: renderComponentIfAllowed,
        allowedRoles: [OFFICE_USER, FACTORY_USER, ADMIN_USER],
      },
      {
        path: createArticlesNewPath(),
        component: (props: RouteConfigComponentProps) => (
          <ArticlesDetail mode="create" {...props} />
        ),
        render: renderComponentIfAllowed,
        allowedRoles: [OFFICE_USER, FACTORY_USER, ADMIN_USER],
      },
      {
        path: createCategoriesPath(),
        exact: true,
        component: Categories,
        render: renderComponentIfAllowed,
        allowedRoles: [OFFICE_USER, FACTORY_USER, ADMIN_USER],
      },
      {
        path: createCategoriesDetailPath(),
        component: (props: RouteConfigComponentProps) => (
          <CategoriesDetail mode="edit" {...props} />
        ),
        render: renderComponentIfAllowed,
        allowedRoles: [OFFICE_USER, FACTORY_USER, ADMIN_USER],
      },
      {
        path: createCategoriesNewPath(),
        component: (props: RouteConfigComponentProps) => (
          <CategoriesDetail mode="create" {...props} />
        ),
        render: renderComponentIfAllowed,
        allowedRoles: [OFFICE_USER, FACTORY_USER, ADMIN_USER],
      },
      {
        path: createCustomersPath(),
        component: Customers,
        exact: true,
        render: renderComponentIfAllowed,
        allowedRoles: [OFFICE_USER, ADMIN_USER],
      },
      {
        path: createCustomerCreatePath(),
        component: CustomersAdd,
        exact: true,
        render: renderComponentIfAllowed,
        allowedRoles: [OFFICE_USER, ADMIN_USER],
      },
      {
        path: createCustomersDetailPath(),
        component: CustomersDetail,
        render: renderComponentIfAllowed,
        allowedRoles: [OFFICE_USER, ADMIN_USER, FACTORY_USER],
      },
      {
        path: createStatisticsPath(),
        exact: true,
        component: Statistics,
        render: renderComponentIfAllowed,
        allowedRoles: [ADMIN_USER],
      },
      {
        path: createStatisticsWorkingHoursPath(),
        component: StatisticsWorkingHours,
        render: renderComponentIfAllowed,
        allowedRoles: [ADMIN_USER, OFFICE_USER],
      },
      {
        path: createPlanningOverviewPath(),
        component: Planning,
        render: renderComponentIfAllowed,
        allowedRoles: [OFFICE_USER, ADMIN_USER],
      },
      {
        path: createSalesMarginsStatisticsPath(),
        component: SalesMarginStatistics,
        render: renderComponentIfAllowed,
        allowedRoles: [OFFICE_USER, ADMIN_USER],
      },
      {
        path: `${createStatisticsPath()}/financial-overview`,
        component: FinancialOverviewPage,
        render: renderComponentIfAllowed,
        allowedRoles: [OFFICE_USER, ADMIN_USER],
      },
      {
        path: createAVPath(),
        component: TermsAndConditionsOverview,
      },

      // CONFIGURATIONSECTION
      {
        path: createConfigurationProcessEmailsPath(),
        component: ConfigurationProcessEmailsIndex,
        render: renderComponentIfAllowed,
        allowedRoles: [CUSTOMER_USER],
      },
      {
        path: `${createConfigurationTenantSettingsPath()}/v2`,
        component: TenantSettings2,
        render: renderComponentIfAllowed,
        allowedRoles: [ADMIN_USER],
      },
      {
        path: createConfigurationTenantSettingsPath(),
        component: TenantSettings,
        render: renderComponentIfAllowed,
        allowedRoles: [ADMIN_USER],
      },

      {
        path: createTaskPath(),
        component: TaskManagmentIndex,
        render: renderComponentIfAllowed,
        allowedRoles: [OFFICE_USER, ADMIN_USER],
        exact: true,
      },
      {
        path: createNewTaskPath(),
        component: (props: RouteConfigComponentProps<{ id: string }>) => (
          <TaskDetails mode="create" {...props} />
        ),
        render: renderComponentIfAllowed,
        allowedRoles: [OFFICE_USER, ADMIN_USER],
      },
      {
        path: createUpdateTaskPath(),
        component: (props: RouteConfigComponentProps<{ id: string }>) => (
          <TaskDetails mode="edit" {...props} />
        ),
        render: renderComponentIfAllowed,
        allowedRoles: [OFFICE_USER, ADMIN_USER],
      },

      // SEARCH
      {
        path: createSearchPath(),
        component: SearchPage,
        render: renderComponentIfAllowed,
        allowedRoles: [OFFICE_USER, FACTORY_USER, ADMIN_USER],
      },

      // Logistics
      {
        path: createExpeditionPath(),
        component: ExpeditionPage,
        render: renderComponentIfAllowed,
        allowedRoles: [OFFICE_USER, FACTORY_USER, ADMIN_USER],
      },
      {
        component: ErrorNotFound,
      },
    ],
  },
];

export default routes;
