import { SyntheticEvent, FC, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Link } from 'react-router-dom';
import { classNames } from 'primereact/utils';


type Props = {
    className?: string;
    items: any[] | null;
    onMenuItemClick?: (values: any) => void;
    root?: boolean;
    onLinkClick?: () => void;
    isLoading?: boolean;
}

const AppSubmenu: FC<Props> = ({ className, items, onMenuItemClick, root, onLinkClick, isLoading }) => {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);
    const { t } = useTranslation();
    const history = useHistory();

    function onClick(event: SyntheticEvent, item: any, index: number) {
        //avoid processing disabled items
        if (item.disabled) {
            event.preventDefault();

            return;
        }

        if (index === activeIndex) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }

        if (onMenuItemClick) {
            onMenuItemClick(item);
        }
    }

    function renderLinkIcon(icon: any) {
        if (!icon) {
            return;
        }
        if (typeof icon === "object") {
            if (icon.fa) {
                return <FontAwesomeIcon style={{ margin: "0 3.5px" }} icon={icon.fa} size="1x" />
            }
            
            return<>{icon}</>
        }

        return <i className={icon}></i>;
    }

    function renderLinkContent(item: any) {
        const submenuIcon = item.items && (
            <i className="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>
        );
        const badge = item.badge && (
            <span className="menuitem-badge">{item.badge}</span>
        );
        const icon = renderLinkIcon(item.icon);

        return (
            <>
                {icon}
                <span>{item.label}</span>
                {submenuIcon}
                {badge}
            </>
        );
    }

    function renderLink(item: any, i: number) {
        const content = renderLinkContent(item);

        if (item.to) {
            return (
                <Link
                    to={item.to}
                    className={history.location.pathname === item.to ? 'is-active' : ''}
                    onClick={() => {
                        if (onMenuItemClick) {
                            onMenuItemClick(item);
                        }
                        
                        setActiveIndex(i);
                    }}     
                >
                    {content}
                </Link>
            );
        }

        return (
            <a
                href={item.url}
                onClick={(e) => onClick(e, item, i)}
                target={item.target}
            >
                {content}
            </a>
        );
    }

    const mappedItems =
        items &&
        items.map((item: any, i: number) => {
            const active = activeIndex === i;
            const styleClass = classNames(item.badgeStyleClass, {
                'active-menuitem': active && !item.to,
                'is-disabled': isLoading
            });

            return (
                <li className={styleClass} key={i}>
                    {item.items && root === true && (
                        <div className="arrow"></div>
                    )}
                    {renderLink(item, i)}
                    <AppSubmenu
                        items={item.items}
                        onMenuItemClick={(item: any) => {
                            if (!onMenuItemClick) {
                                return;
                            }

                            onMenuItemClick(item)
                        }}
                        onLinkClick={onLinkClick}
                    />
                </li>
            );
        });

    return mappedItems ? <ul className={className}>{mappedItems}</ul> : null;
}

AppSubmenu.defaultProps = {
    items: null,
    root: false,
};

export default AppSubmenu;
