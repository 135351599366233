import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Island from '../../components/shared/controls/Island/Island';
import {
  StockArticleDto,
  StockStorageLocationDto,
  StockWarehouseDto,
} from '../../api/core-client';
import { useStock } from '../../hooks/useCore';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';

import { confirmDialog } from 'primereact/confirmdialog';
import { AddWarehouseDialog } from './partials/AddWarehouseDialog';
import { NotificationContext } from '../../context/NotificationContext';
import parseHtml from 'react-html-parser';
import { AddEditLocationDialog } from './partials/AddEditLocationDialog';

const StockManagementPage: FC = () => {
  const { t } = useTranslation(['stock', 'common']);
  const coreStock = useStock();
  const notificationContext = useContext(NotificationContext);

  const [warehouseLoading, setWarehouseLoading] = useState<boolean>(true);
  const [locationsLoading, setLocationsLoading] = useState<boolean>(false);
  const [goodsLoading, setGoodsLoading] = useState<boolean>(false);

  const [warehouses, setWarehouses] = useState<StockWarehouseDto[]>([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState<
    StockWarehouseDto | undefined
  >();
  const selectedWarehouseId = useMemo(
    () => selectedWarehouse?.id ?? -1,
    [selectedWarehouse],
  );

  const [warehouseLocations, setWarehouseLocations] = useState<
    StockStorageLocationDto[]
  >([]);

  const [goods, setGoods] = useState<StockArticleDto[]>([]);

  const [selectedLocation, setSelectedLocation] = useState<
    StockStorageLocationDto | undefined
  >();
  const selectedLocationId = useMemo(
    () => selectedLocation?.id ?? -1,
    [selectedLocation],
  );
  const [selectedLocationPage, setSelectedLocationPage] = useState<number>(1);

  const [showAddEditWarehouseDialog, setShowAddEditWarehouseDialog] =
    useState<boolean>(false);
  const dialogWarehouseDataRef = useRef<StockWarehouseDto | undefined>(
    undefined,
  );
  const dialogWarehouseModeRef = useRef<'ADD' | 'EDIT'>('ADD');

  const [showAddEditLocationDialog, setShowAddEditLocationDialog] =
    useState<boolean>(false);
  const dialogLocationDataRef = useRef<StockStorageLocationDto | undefined>(
    undefined,
  );
  const dialogLocationModeRef = useRef<'ADD' | 'EDIT'>('ADD');

  const _showLocationDialog = useCallback(
    (mode: 'ADD' | 'EDIT', locationData?: StockStorageLocationDto) => {
      if (mode === 'EDIT' && !locationData) {
        if (notificationContext) {
          notificationContext.showWarningNotification(
            'Unable to show edit location dialog without warehouse data',
          );
        } else {
          alert('Unable to show edit location dialog without warehouse data');
        }
        return;
      }

      if (mode === 'ADD') {
        dialogLocationDataRef.current = { warehouseId: selectedWarehouseId };
      } else {
        dialogLocationDataRef.current = {
          ...locationData,
          warehouseId: selectedWarehouseId,
        };
      }

      dialogLocationModeRef.current = mode;

      setShowAddEditLocationDialog(true);
    },
    [notificationContext, selectedWarehouseId],
  );

  const _showWarehouseDialog = useCallback(
    (mode: 'ADD' | 'EDIT', warehouseData?: StockWarehouseDto) => {
      if (mode === 'EDIT' && !warehouseData) {
        if (notificationContext) {
          notificationContext.showWarningNotification(
            'Unable to show edit warehouse dialog without warehouse data',
          );
        } else {
          alert('Unable to show edit warehouse dialog without warehouse data');
        }
        return;
      }

      if (mode === 'ADD') {
        dialogWarehouseDataRef.current = undefined;
      } else {
        dialogWarehouseDataRef.current = warehouseData;
      }

      dialogWarehouseModeRef.current = mode;

      setShowAddEditWarehouseDialog(true);
    },
    [notificationContext],
  );

  const _showRemoveWarehouseDialog = useCallback(
    async (warehouseData?: StockWarehouseDto) => {
      if (!warehouseData) {
        if (notificationContext) {
          notificationContext.showWarningNotification(
            'Unable to show remove warehouse dialog without warehouse data',
          );
        } else {
          alert(
            'Unable to show remove warehouse dialog without warehouse data',
          );
        }
        return;
      }

      confirmDialog({
        header: t('stock:dialog.delete-warehouse.delete-warehouse'),
        modal: true,
        closable: false,
        acceptLabel: t('common:yes'),
        acceptClassName: 'button-danger-important',
        rejectLabel: t('common:no'),

        message: () => {
          return (
            <div className="w-full">
              <p>
                {parseHtml(
                  t(
                    'stock:dialog.delete-warehouse.delete-warehouse-x-are-you-sure',
                    { replace: { warehouseName: warehouseData.name } },
                  ),
                )}
              </p>
            </div>
          );
        },
        accept: async () => {
          if (warehouseData.id)
            await coreStock.DeleteWarehouse(warehouseData.id);
        },
      });
    },
    [notificationContext],
  );

  useEffect(() => {
    setWarehouses(coreStock.warehouses ?? []);
    setWarehouseLoading(false);
  }, [coreStock?.warehouses]);

  useEffect(() => {
    if ((selectedWarehouseId ?? 0) <= 0) return;
    setLocationsLoading(true);
    setSelectedLocation(undefined);
    coreStock
      .ListLocationsForWarehouse(selectedWarehouseId, selectedLocationPage, 15)
      .then((data) => {
        setWarehouseLocations(data);
      })
      .finally(() => setLocationsLoading(false));
  }, [selectedWarehouseId, coreStock.ListLocationsForWarehouse]);

  useEffect(() => {
    if ((selectedWarehouseId ?? 0) <= 0 || (selectedLocationId ?? 0) <= 0)
      return;

    setGoodsLoading(true);
    coreStock
      .ListArticlesOnLocation(selectedWarehouseId, selectedLocationId)
      .then((data) => {
        setGoods(data);
      })
      .finally(() => setGoodsLoading(false));
  }, [selectedLocationId]);

  const PlusMinusEditButtons = (
    onPlusClick?,
    onMinusClick?,
    onEditClick?,
    plusDisabled = false,
    minusDisabled = false,
    editDisabled = true,
  ) => {
    return (
      <>
        <Button
          icon="pi pi-plus"
          onClick={onPlusClick}
          disabled={plusDisabled}
        />
        <Button
          icon="pi pi-minus"
          disabled={minusDisabled}
          onClick={onMinusClick}
        />
        <Button
          icon="pi pi-pencil"
          disabled={editDisabled}
          onClick={onEditClick}
        />
      </>
    );
  };

  return (
    <>
      <Island title={t('stock:stockmanagement')}>
        <div className="flex flex-column gap-4">
          <DataTable
            loading={warehouseLoading}
            className="w-full"
            header={
              <div className="w-full flex flex-row gap-3 align-items-center justify-content-between">
                <span>{t('stock:warehouses')}</span>
                <div className="flex flex-row gap-2">
                  {PlusMinusEditButtons(
                    () => _showWarehouseDialog('ADD'),
                    () => _showRemoveWarehouseDialog(selectedWarehouse),
                    () => _showWarehouseDialog('EDIT', selectedWarehouse),
                    false,
                    selectedWarehouseId <= 0,
                    selectedWarehouseId <= 0,
                  )}
                </div>
              </div>
            }
            value={warehouses}
            selection={selectedWarehouse}
            selectionMode="single"
            onSelectionChange={(e) => setSelectedWarehouse(e.value)}
          >
            <Column
              className="w-12 sm:w-5"
              header={t('common:name')}
              field="name"
              sortable
            />
            <Column
              className="w-12 sm:w-8"
              header={t('common:description')}
              field="description"
            />
          </DataTable>
          <div className="w-full flex flex-column gap-4 sm:flex-row">
            <div className="w-12 sm:w-6">
              <DataTable
                className="w-full"
                loading={warehouseLoading || locationsLoading}
                header={<div className="w-full flex flex-row gap-3 align-items-center justify-content-between">
                  <span>{t('stock:storage-locations')}</span>
                  <div className="flex gap-2">
                    {PlusMinusEditButtons(
                      () => _showLocationDialog('ADD'),
                      () => alert('Show remove LOCATION Dialog'),
                      () => _showLocationDialog('EDIT', selectedLocation),
                      selectedWarehouseId <= 0,
                      selectedWarehouseId <= 0 || selectedLocationId <= 0,
                      selectedWarehouseId <= 0 || selectedLocationId <= 0,
                    )}
                  </div>
                </div>}
                paginator
                rows={15}
                value={locationsLoading ? [] : warehouseLocations}
                onPage={(evt) => {
                  setSelectedLocationPage(evt.page ?? 1);
                }}
                selection={selectedLocation}
                selectionMode="single"
                onSelectionChange={(e) => setSelectedLocation(e.value)}
               
              >
                <Column field="name" header={t('common:name')} />
                <Column field="locationType" header={t('stock:locationType')} />
              </DataTable>
            </div>
            <div className="w-12 sm:w-6">
              <DataTable
                loading={warehouseLoading || locationsLoading || goodsLoading}
                className={classNames('w-full', {
                  'opacity-30': selectedLocationId <= 0,
                })}
                header={
                  <div className="w-full flex gap-2 align-items-center justify-content-between">
                    <span>{t('stock:stored-goods')}</span>
                  </div>
                }
                value={goods}
                paginator
                rows={15}
              >
                <Column field="brandName" header={t('stock:brand')} />
                <Column field="name" header={t('common:name')} />
                <Column
                  field="availableUnits"
                  header={t('common:amount')}
                  className="sm:w-1 text-right"
                />
                <Column
                  field="id"
                  className="sm:w-1"
                  body={(data) => (
                    <>
                      <Button
                        icon="pi pi-info-circle"
                        onClick={() =>
                          alert('Show information ' + data['id'].toString())
                        }
                      />
                    </>
                  )}
                />
              </DataTable>
            </div>
          </div>
        </div>
      </Island>

      <AddWarehouseDialog
        onHide={() => {
          setShowAddEditWarehouseDialog(false);
        }}
        visible={showAddEditWarehouseDialog}
        warehouseData={dialogWarehouseDataRef.current}
        mode={dialogWarehouseModeRef.current}
        onSave={async (data) => {
          if (!data) return;
          switch (dialogWarehouseModeRef.current) {
            case 'ADD':
              if (await coreStock.AddWarehouse(data))
                setShowAddEditWarehouseDialog(false);
              break;

            case 'EDIT':
              if (data.id)
                if (await coreStock.UpdateWarehouse(data.id, data))
                  setShowAddEditWarehouseDialog(false);
              break;
          }
        }}
      />
      <AddEditLocationDialog
        onHide={() => {
          setShowAddEditLocationDialog(false);
        }}
        visible={showAddEditLocationDialog}
        locationData={dialogLocationDataRef.current}
        mode={dialogLocationModeRef.current}
        onSave={async (data) => {
          if (!data) return;
          switch (dialogLocationModeRef.current) {
            case 'ADD':
              if (await coreStock.AddLocation(data))
                setShowAddEditLocationDialog(false);
              break;

            case 'EDIT':
              if (data.id)
                if (await coreStock.UpdateLocation(data.id, data))
                  setShowAddEditLocationDialog(false);
              break;
          }
        }}
      />
    </>
  );
};

export default StockManagementPage;
