import { StockArticleDto, StockStorageLocationDto } from '@app/api/core-client';
import { useStock } from '@app/hooks/useCore';
import { PlusMinus } from '@app/sharedicons';
import { Formik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog, DialogProps } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { TreeSelect } from 'primereact/treeselect';
import { classNames } from 'primereact/utils';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import * as yupLocales from 'yup-locales';

export interface MutateStockDialogProps extends DialogProps {
  articleId?: number;
  onSave?: (data) => void | Promise<void>;
}

export const MutateStockDialog: FC<MutateStockDialogProps> = (dialogprops) => {
  const {
    children,
    header,
    footer,
    className,
    articleId,
    onSave,
    ...otherprops
  } = dialogprops;
  const { t, i18n } = useTranslation(['common', 'stock']);
  const stockApi = useStock();

  useEffect(() => {
    let lng = i18n.language?.substring(0, 2).toLowerCase();
    if (yupLocales[lng]) {
      yup.setLocale(yupLocales[lng]);
    } else {
      yup.setLocale(yupLocales.en);
    }
  }, [i18n.language]);

  const [editWarehouse, setEditWarehouse] = useState<boolean>(false);
  const [articleInformation, setArticleInformation] = useState<
    StockArticleDto | undefined
  >();
  const [mutationDialogArticleLocations, setMutationDialogArticleLocations] =
    useState<StockStorageLocationDto[]>([]);
  const [mutationDialogArticles, setMutationDialogArticles] = useState<
    StockArticleDto[]
  >([]);

  useEffect(() => {
    // load article details
    if (stockApi && articleId) {
      stockApi
        .GetArticleDetails(articleId)
        .then((d) => {
            setArticleInformation(d);
            stockApi.ListArticlesForCategoryId(d?.categoryId).then(d2=>setMutationDialogArticles(d2));
            
        });
        stockApi.GetAvailableArticleLocations(articleId).then((d)=>{
            setMutationDialogArticleLocations(d);
        })
    } else {
      setArticleInformation(undefined);
    }
  }, [stockApi.GetArticleDetails, stockApi.ListArticlesForCategoryId, articleId]);

  const _initialMutationValues = useMemo(() => {
    let result = {
      categoryId: 0,
      articleId: 0,
      warehouseId: 0,
      locationId: 0,
      amountMutation: 0,
    };

    if (articleInformation) {
      result.amountMutation = 0;
      result.articleId = articleInformation.id ?? 0;
      result.categoryId = articleInformation.categoryId ?? 0;
    }

    return result;
  }, [articleInformation]);

  return (
    <Dialog
      {...otherprops}
      className={classNames(className, 'w-12 sm:w-4')}
      header={
        <div className="flex flex-row gap-2 align-items-center">
          <PlusMinus size={18} />
          <span>{t('stock:dialog.article-mutations.article-mutations')}</span>
        </div>
      }
    >
      <Formik
        enableReinitialize
        initialValues={_initialMutationValues}
        onSubmit={async (values, helpers) => {
          helpers.setSubmitting(true);
          try {
            if (onSave) await onSave(values);
          } finally {
            helpers.setSubmitting(false);
          }
        }}
      >
        {({ values, errors, getFieldHelpers, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <div className="formgrid grid">
              <div className="form-group col-12 sm:col-6">
                <label htmlFor="id" className="form-label">
                  {t('common:category')}
                </label>
                <TreeSelect
                  disabled={isSubmitting}
                  className="w-full"
                  options={stockApi.categoryTree}
                  value={String(values.categoryId)}
                  style={{ paddingTop: '0.18rem', paddingBottom: '0.18rem' }}
                  onChange={(e) => {
                    getFieldHelpers('categoryId').setValue(e.value);
                    let numValue = Number(e.value);
                    if (!isNaN(numValue))
                      stockApi
                        .ListArticlesForCategoryId(numValue)
                        .then((data) => {
                          setMutationDialogArticles(data);
                          getFieldHelpers('articleId').setValue(null);
                        });
                  }}
                />
                {errors.categoryId && (
                  <small className="danger">{errors.categoryId}</small>
                )}
              </div>
              <div className="form-group col-12 sm:col-6">
                <label htmlFor="articleId" className="form-label">
                  {t('stock:article')}
                </label>
                <Dropdown
                  disabled={isSubmitting}
                  inputId="articleId"
                  className="w-full"
                  options={mutationDialogArticles}
                  value={values.articleId}
                  emptyMessage={t('common:no-items')}
                  itemTemplate={(option) => (
                    <div className="w-full flex flex-column">
                      <div className="w-full flex gap-2 flex-row">
                        <div className="w-8 font-bold">{option.name}</div>
                        <div className="w-4 font-italic text-right">
                          {option.brandName}
                        </div>
                      </div>
                      <div className="w-12 sm:w-6 text-xs ">
                        {option.description}
                      </div>
                    </div>
                  )}
                  optionLabel="name"
                  optionValue="id"
                  onChange={(e) => {
                    getFieldHelpers('articleId').setValue(e.value);
                    let numValue = Number(e.value);
                    if (!isNaN(numValue)) {
                      stockApi
                        .GetAvailableArticleLocations(numValue)
                        .then((data) => {
                          setMutationDialogArticleLocations(data);
                        });
                    }
                  }}
                />
                {errors.articleId && (
                  <small className="danger">{errors.articleId}</small>
                )}
              </div>
              <div className="form-group col-12">
                <label htmlFor="warehouseId" className="form-label">
                  {t('stock:warehouse-literal')}
                </label>
                <div className="p-inputgroup flex-1">
                  <Dropdown
                    disabled={isSubmitting || !editWarehouse}
                    options={stockApi.warehouses}
                    optionValue="id"
                    optionLabel="name"
                    itemTemplate={(option) => (
                      <div className="flex w-full flex-column">
                        <div className="w-12 font-bold">{option.name}</div>
                      </div>
                    )}
                    value={values.warehouseId}
                    onChange={(e) => {
                      getFieldHelpers('warehouseId').setValue(e.value);
                    }}
                  />
                  <span className="p-inputgroup-addon">
                    <i
                      onClick={() => setEditWarehouse((old) => !old)}
                      className={`pi pi-${
                        !editWarehouse ? 'lock' : 'lock-open'
                      }`}
                    ></i>
                  </span>
                </div>
                {errors.warehouseId && (
                  <small className="danger">{errors.warehouseId}</small>
                )}
              </div>
              <div className="form-group col-12">
                <label
                  htmlFor="alternativeArticleLocationId"
                  className="form-label"
                >
                  {t('stock:location')}
                </label>
                <Dropdown
                  disabled={isSubmitting}
                  options={editWarehouse ? stockApi.storageLocations.filter(x=>values.warehouseId != 0 ? x.warehouseId == values.warehouseId : true ) : mutationDialogArticleLocations }
                  optionValue="id"
                  optionLabel="name"
                  itemTemplate={(option) => (
                    <div className="flex w-full flex-column gap-1">
                      <div className="w-12 font-bold">{option.name}</div>
                      <div className="flex flex-row gap-2 align-items-center">
                        <div className="w-3 sm:w-1 text-xs">
                          {t('stock:warehouse-literal')}:
                        </div>
                        <span className="text-xs font-italic">{stockApi.warehouses?.find(x=>x.id == option.warehouseId)?.name}</span>
                      </div>
                    </div>
                  )}
                  value={values.locationId}
                  onChange={(e) => {
                    getFieldHelpers('locationId').setValue(e.value);
                  }}
                />
                {errors.locationId && (
                  <small className="danger">{errors.locationId}</small>
                )}
              </div>
              <div className="form-group col-12">
                <label htmlFor="amountMutation" className="form-label">
                  {t('stock:dialog.article-mutations.amount')}
                </label>
                <div className="flex gap-3 align-items-center">
                  <InputNumber
                    disabled={isSubmitting}
                    id="amountMutation"
                    min={-9999999}
                    step={0.0001}
                    max={9999999}
                    minFractionDigits={0}
                    maxFractionDigits={4}
                    mode="decimal"
                    value={values.amountMutation}
                    onChange={(e) =>
                      getFieldHelpers('amountMutation').setValue(e.value)
                    }
                  />
                </div>
                {errors.amountMutation && (
                  <small className="danger">{errors.amountMutation}</small>
                )}
              </div>
            </div>
            <div className="w-full flex flex-row-reverse">
              <Button
                disabled={isSubmitting}
                className="w-full sm:w-max"
                icon="pi pi-save mr-2"
                type="submit"
              >
                {t('common:actions.save')}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
