import { FC } from 'react';
import Modal from 'react-modal';
import { MdClose } from '@app/sharedicons';

import '../../../../assets/styles/sass/_react-modal.scss';
import { classNames } from 'primereact/utils';

type Props = {
  isOpen: boolean;
  title: string,
  subTitle?: string,
  onClose?: () => void;
  className?: string;
  isSmall?: boolean;
  scrollYOverflow?: boolean;
};

const InternalModal: FC<Props> = ({
  children,
  isOpen,
  title,
  subTitle,
  onClose,
  className,
  isSmall,
  scrollYOverflow
}) => {
  const modalClassName = classNames(className, {
    'is-small': isSmall,
    'no-overflow': !scrollYOverflow
  });

  return (
    <Modal
      isOpen={isOpen}
      ariaHideApp={false}
      className={modalClassName}
      shouldCloseOnOverlayClick
      onRequestClose={(e: any) => {
        e.stopPropagation();
        if (!onClose) {
          return;
        }
        onClose();
      }}
    >
      {onClose && (
        <div
          onClick={(e: any) => {
            e.stopPropagation();
            onClose();
          }}
          className="ReactModal__Close"
        >
        </div>
      )}
      <div className="react-modal__header">
        <div>
          <h1 className="react-modal__heading">{title}</h1>
          {subTitle &&
            <strong>
              {subTitle}
            </strong>
          }
        </div>
        {onClose &&
                <MdClose size="1.5em" onClick={onClose} />
        }
      </div>
      {children}
    </Modal>
  );
};

export default InternalModal;
