import {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks/useAuth';
import {
  Configuration as ProductApiConfiguration,
  ProductApi,
  ProductModel,
} from '../../api/product';
import { RouteComponentProps } from 'react-router-dom';
import Island from '../../components/shared/controls/Island/Island';
import { BOMContextProvider } from '../../components/shared/controls/BOM/BOMContext';
import { BOMSpecEditor } from '../../components/shared/controls/BOM/BOMSpecEditor';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { BOMItemValue } from '../../components/shared/controls/BOM/BOMItem';
import { DataTable } from 'primereact/datatable';
import {
  BillOfMaterialTemplateDto,
} from '../../api/core-client';
import { useBOM } from '../../hooks/useCore';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';

export const BOMTemplatePage: FC<RouteComponentProps<{ id?: string }>> = ({
  match,
}) => {
  const { t } = useTranslation(['common']);
  const { auth } = useAuth();

  const { ListBOMTemplatesForProduct,UpdateBOMTemplateForProduct   } = useBOM();

  const [productData, setProductData] = useState<ProductModel | undefined>();
  const [availableTemplates, setAvailableTemplates] = useState<
    BillOfMaterialTemplateDto[]
  >([]);

  const [activeBOMTemplateDefinition, setActiveBOMTemplateDefinition] =
    useState<string | BOMItemValue[]>([]);
  const [activeBOMTemplateIdx, setActiveBOMTemplateIdx] = useState<number>(-1);

  const productApi = useMemo(
    () =>
      new ProductApi(
        new ProductApiConfiguration({
          basePath: process.env.REACT_APP_SERVICE_URL_PRODUCT,
          accessToken: auth?.jwt,
        }),
      ),
    [auth?.jwt],
  );

  const IslandTitle = useMemo(() => {
    if (productData && productData.name) {
      return `"${productData.name}" BOM`;
    }
    return 'Product BOM';
  }, [productData]);

  useLayoutEffect(() => {
    if (!match.params.id) return;
    const prodId = Number(match.params.id);

    Promise.all([
      productApi.getProductById(prodId),
      ListBOMTemplatesForProduct(prodId),
    ]).then(([prodResponse, bomTemplates]) => {
      if (prodResponse.status == 200) {
        setProductData(prodResponse.data);
      } else {
        setProductData(undefined);
      }

      setAvailableTemplates(bomTemplates);
    });
  }, [match.params?.id]);

  const saveBOMTemplate = useCallback(
    async (data: BOMItemValue[]) => {
      if (!match.params.id) return;

      const prodId = Number(match.params.id);

      const filtered = data?.filter(
        (x) => x.articleId && x.categoryId && x.assignedAmount,
      );
      if (filtered?.length > 0) {
        const serialized = JSON.stringify(filtered);

        let tmpl = availableTemplates[activeBOMTemplateIdx];
        tmpl.templateContent = serialized;
        tmpl.productId = tmpl.productId ?? prodId;

        const updateResult = await UpdateBOMTemplateForProduct(tmpl.productId, tmpl);

        if (updateResult) {
            setAvailableTemplates((old)=>{
                let n = old.map((v,i)=>{
                    if (i == activeBOMTemplateIdx) {
                        return updateResult;
                    }
                    return v
                });
                return n;
            })
        }

      }
    },
    [productApi, match.params?.id, availableTemplates, activeBOMTemplateDefinition],
  );

  useEffect(() => {
    if (
      activeBOMTemplateIdx >= 0 &&
      availableTemplates.length > activeBOMTemplateIdx
    ) {
      setActiveBOMTemplateDefinition(
        availableTemplates[activeBOMTemplateIdx].templateContent ?? [],
      );
    }
  }, [activeBOMTemplateIdx]);

  return (
    <Island title={IslandTitle}>
      <div className="w-full flex flex-column px-4">
        <DataTable
          emptyMessage={t('common:no-items')}
          value={availableTemplates}
          header="Available BOM templates"
          showHeaders={false}
          footer={
            <div className="w-full flex">
              <Button
                icon="pi pi-plus"
                onClick={() => {
                  let name = prompt(
                    'Naam voor het nieuwe BOM Template',
                    `${productData?.name} - template ${
                      availableTemplates.length + 1
                    }`,
                  );

                  if (!name) return;

                  let item: BillOfMaterialTemplateDto = {
                    id: 0,
                    name: name,
                    productId: Number(match.params.id),
                    templateContent: '[]',
                  };

                  setAvailableTemplates((old) => {
                    return [...old, item];
                  });
                }}
              />
            </div>
          }
        >
          <Column
            field="id"
            header=""
            body={(data, options) => {
              return (
                <Checkbox
                  value={data['id']}
                  onChange={() => setActiveBOMTemplateIdx(options.rowIndex)}
                  checked={activeBOMTemplateIdx == options.rowIndex}
                />
              );
            }}
          />
          <Column field="name" header={t('name')} />
        </DataTable>
      </div>
      <DndProvider backend={HTML5Backend}>
        <BOMContextProvider bomDefinition={activeBOMTemplateDefinition}>
          <div className="w-full p-4">
            <BOMSpecEditor
              className="bom-spec-preview-window"
              onSave={saveBOMTemplate}
            />
          </div>
        </BOMContextProvider>
      </DndProvider>
    </Island>
  );
};
